import React from "react";
import Layout from "../components/layout";
import HowToOrderItem from "../components/how-to-order-item";
import Breadcrumb from "../components/breadcrumb";
import GlobalConstants from "../helpers/constants";
import Axios from "axios";
import { CrystallizeBaseURL } from "../helpers/constants";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { Grid, Typography } from "@material-ui/core";
import { GetComponentByName } from "../helpers/query-data";
import { GQLQuery, GQLRichTextContent } from "../types";

const MyQuery = `
  {
    catalogue(path: "/articles/how-to-order") {
      name
      components {
        name
        content {
          ...H2O_richTextContent
        }
      }
      children {
        ...H2O_item
      }
    }
  }

  fragment H2O_item on Item {
    name
    components {
      name
      content {
        ...H2O_singleLineContent
        ...H2O_richTextContent
        ...H2O_imageContent
      }
    }
  }

  fragment H2O_singleLineContent on SingleLineContent {
    text
  }

  fragment H2O_richTextContent on RichTextContent {
    html
  }

  fragment H2O_imageContent on ImageContent {
    images {
      url
    }
  }
`;

const HowToOrder = (): JSX.Element => {
  const PathObj = GetSimplePathObj(GlobalConstants.HowToOrder);
  const [QueryResult, SetQueryResult] = React.useState({} as GQLQuery);

  Axios.post(CrystallizeBaseURL, { query: MyQuery }).then((response) => {
    SetQueryResult(response.data.data as GQLQuery);
  });

  const DescriptionCompContent = GetComponentByName(
    QueryResult.catalogue?.components ?? [],
    GlobalConstants.Description
  )?.content as GQLRichTextContent;

  return (
    <Layout currentPage="/how-to-order">
      <Breadcrumb pathObj={PathObj} />
      <Grid item xs={12}>
        <Typography variant="h6">{QueryResult.catalogue?.name}</Typography>
        {DescriptionCompContent?.html.map((node, index) => (
          <div
            key={index}
            className="MuiTypography-root MuiTypography-body2"
            dangerouslySetInnerHTML={{ __html: node }}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        {QueryResult.catalogue?.children.map((node, index) => (
          <HowToOrderItem key={index} article={node} />
        ))}
      </Grid>
    </Layout>
  );
};

export default HowToOrder;
